import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

import SignIn from "./SignIn";

import SignupForm from "../components/SignupForm";

import logo from "../assets/images/logos/logo.png";
import ads from "../assets/logo.png";
import bg2 from "../assets/bg2.jpg"
const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
  // Add more testimonials as needed
];

const cardData = [
  {
    title: "Real-Time Market Data",
    content:
      "Access up-to-date market data and trends to make informed trading decisions.",
    image: "https://source.unsplash.com/random?stock-market",
    action: "View Market Trends",
    actionLink: "/market",
  },
  {
    title: "Commodity Trading",
    content:
      "Trade a variety of commodities from grains to metals anywhere, anytime.",
    image: "https://source.unsplash.com/random?commodity",
    action: "Start Trading",
    actionLink: "/trade",
  },
  {
    title: "Portfolio Management",
    content:
      "Manage and review your investment portfolio to keep track of your assets and their performance.",
    image: "https://source.unsplash.com/random?portfolio",
    action: "Manage Portfolio",
    actionLink: "/portfolio",
  },
  {
    title: "Automated Trading",
    content:
      "Set up trading bots to automate your trading strategies and capitalize on market opportunities 24/7.",
    image: "https://source.unsplash.com/random?automation",
    action: "Automate Trades",
    actionLink: "/automation",
  },
  {
    title: "Secure Transactions",
    content:
      "All transactions are secured with industry-standard encryption, ensuring your data and assets are protected.",
    image: "https://source.unsplash.com/random?secure-transaction",
    action: "Learn More",
    actionLink: "/security",
  },
  {
    title: "Global Access",
    content:
      "Engage with international markets and expand your trading boundaries. Perfect for the diaspora looking to invest back home.",
    image: "https://source.unsplash.com/random?global-trade",
    action: "Go Global",
    actionLink: "/global",
  },
  // Add more card data as needed
];

const Terms = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    const endpoint = email.endsWith("@accentrixcloud.com")
      ? "https://theyard.accentrixcloud.com/api/accounts"
      : "https://theyard.accentrixcloud.com/api/users";
    const profileRoute = email.endsWith("@accentrixcloud.com")
      ? "/admin-profile"
      : "/user-profile";

    try {
      const response = await axios.post(
        `https://theyard.accentrixcloud.com${endpoint}`,
        { email, password }
      );
      if (response.status === 200) {
        navigate(profileRoute, { state: { accountDetails: response.data } });
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      setDialogMessage(`An error occurred while signing in: ${error.message}`);
      setOpen(true);
    }
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${bg2})`,
    position: "relative", // Needed for absolute positioning of the overlay
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  const logoStyle = {
    position: "absolute", // Absolute positioning within the relative banner
    top: 20, // Adjust these values based on your specific needs
    left: 20,
    height: "180px",
    zIndex: 10, // Make sure it's above the overlay
  };

  return (
    <div>
      <div style={bannerStyle}>
        <img src={logo} alt="Logo" style={logoStyle} />
        <div style={overlayStyle}></div>
      </div>
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
          minHeight: `calc(100vh - ${navbarHeight}px)`,
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={3} sx={{ margin: 'auto', padding: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mt: 2 }}>
            Terms of Service
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By registering or using the services at The Yard - Trading House, you agree to adhere to these terms and conditions. If you disagree with any part of the terms, you may discontinue use of our services.
          </Typography>
          <Typography variant="h6" gutterBottom>
            2. Scope of Service
          </Typography>
          <Typography paragraph>
            The Yard - Trading House facilitates transactions between agricultural and mineral producers of all sizes and buyers looking to purchase in bulk for manufacturing or other value-adding activities. Our platform is designed to alleviate inefficiencies in the value chain by ensuring prompt payments and reducing waste.
          </Typography>
          <Typography variant="h6" gutterBottom>
            3. Financial Transactions
          </Typography>
          <Typography paragraph>
            Our platform supports investments starting as low as Ksh 10. Investments not meeting the minimum requirements are pooled into consolidated accounts until they achieve a viable size. Users can opt-out and liquidate their investments when they mature. All financial transactions, especially those involving mobile money transfers, are processed under stringent security measures to protect your financial data.
          </Typography>
          <Typography variant="h6" gutterBottom>
            4. Returns and Refunds
          </Typography>
          <Typography paragraph>
            We strive to process returns promptly and aim to complete them within 12 weeks from the claim date. This period allows for the adequate processing of returns and reflects our commitment to service efficiency.
          </Typography>
          <Typography variant="h6" gutterBottom>
            5. Data Use and Privacy
          </Typography>
          <Typography paragraph>
            We collect necessary information, such as your name, email, and phone number for registration, communications, and to facilitate transactions. Your email will be used for regular notifications which you can opt out of by unsubscribing via the link provided in the email.
          </Typography>
          <Typography variant="h6" gutterBottom>
            6. Disclaimer
          </Typography>
          <Typography paragraph>
            The Yard - Trading House provides its services "as is" without any express or implied warranties. We do not guarantee the uninterrupted or error-free operation of our services or the accuracy of any financial advice or market prediction.
          </Typography>
          <Typography variant="h6" gutterBottom>
            7. Limitation of Liability
          </Typography>
          <Typography paragraph>
            The platform is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services, including but not limited to, losses or damages from the suspension or termination of service and data inaccuracies.
          </Typography>
          <Typography variant="h6" gutterBottom>
            8. Modifications to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these terms at any time. Changes will be posted on our platform and effective immediately. Your continued use of our services following any such changes will constitute your acceptance of the new terms.
          </Typography>
          <Typography variant="h6" gutterBottom>
            9. Contact Information
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns regarding these terms, please contact us at info@accentrixcloud.com.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
    <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4} style={{ color: "#3f51b5", textAlign:'center' }}>
          <img src={ads} alt="Logo" style={{height: 80}} />
            <Typography >
            A product of Accentrix Data Systems Ltd
            </Typography>
            <Typography variant="caption" >All Rights Reserved  © {new Date().getFullYear()} </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} style={{flexGrow: 0.1, display: "flex", justifyContent: "center", flexDirection: "column", justifyContent: "center", alignItems: "center",}} >
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Terms;
