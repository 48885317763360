import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Paper,
  Button
} from "@mui/material";
import Title from "./Title";
import Projection from "./Projection"; // Import the Projection component
import { useUser } from "../UserContext";

export default function Orders() {
  const [commodities, setCommodities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCommodityId, setSelectedCommodityId] = useState(null);
  const { user } = useUser();
  useEffect(() => {
    async function fetchCommodities() {
      try {
        const { data } = await axios.get("https://theyard.accentrixcloud.com/api/commodities");
        const fetchedCommodities = data.map((commodity) => ({
          ...commodity,
          minQty: Math.ceil(commodity.qty * 0.1),
          startingCapital: Math.ceil(commodity.qty * 0.1 * commodity.price),
        }));
        setCommodities(fetchedCommodities);
      } catch (err) {
        console.error("Failed to fetch commodities:", err);
      } finally {
        setLoading(false);
      }
    }
    fetchCommodities();
  }, []);

  const handleRowClick = (id) => {
    setSelectedCommodityId(id);
  };

  const handleBackClick = () => {
    setSelectedCommodityId(null);
  };

  return (
    <React.Fragment>
      <Title>Your Portfolio</Title>
      {loading ? (
        <CircularProgress />
      ) : selectedCommodityId ? (
        <div>
          <Projection id={selectedCommodityId} />
        </div>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Min Qty</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Starting Capital (Ksh)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commodities.length > 0 ? commodities.map((commodity) => (
              <TableRow key={commodity.id} hover onClick={() => handleRowClick(commodity.id)}>
                <TableCell>{commodity.name}</TableCell>
                <TableCell>{commodity.minQty.toLocaleString()}</TableCell>
                <TableCell>{`Ksh ${commodity.startingCapital.toLocaleString()}`}</TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  No commodities to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
}
