import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import Homepage from "./pages/Homepage";
import AdminProfile from "./components/AdminProfile";
import UserProfile from "./components/UserProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Commodities from "./components/Commodities";
import Trading from "./components/Trading";
import UserAccounts from "./components/UserAccounts";
import Orders from "./components/Orders";
import Projection from "./components/Projection";
import ProfileSettings from "./components/ProfileSettings";
import { UserProvider } from './UserContext';
import SetPassword from "./components/SetPassword";
import SignIn from "./pages/SignIn";
import ProtectedRoute from './ProtectedRoute';
import Analytics from './Analytics';  // Import your new component

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UserProvider>
          <Analytics />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/admin-profile" element={<ProtectedRoute><AdminProfile /></ProtectedRoute>} />
            <Route path="/projections" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/commodities" element={<ProtectedRoute><Commodities /></ProtectedRoute>} />
            <Route path="/trading" element={ <ProtectedRoute><Trading /></ProtectedRoute>} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/user-accounts" element={<ProtectedRoute><UserAccounts /></ProtectedRoute>} />
            <Route path="/profile-settings" element={<ProtectedRoute><ProfileSettings /></ProtectedRoute>} />
          </Routes>
        </UserProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
