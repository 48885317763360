import React from "react";
import { Link } from "react-router-dom";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";

const AdminMenu = () => {
  return (
    <Box sx={{ height: 220, flexGrow: 1, maxWidth: 400 }}>
      <SimpleTreeView>
        <TreeItem
          itemId="dashboard"
          label={
            <Link
              to="/admin-profile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              Dashboard{" "}
            </Link>
          }
        />
        <TreeItem itemId="commodities" label="Commodities">
          <TreeItem
            itemId="viewCommodities"
            label={
              <Link
                to="/commodities"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                view{" "}
              </Link>
            }
          />
        </TreeItem>
        <TreeItem itemId="deposits" label="Deposits">
          <TreeItem
            itemId="viewDeposits"
            label={
              <Link
                to="/deposits"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                view{" "}
              </Link>
            }
          />
        </TreeItem>
        <TreeItem itemId="userAccounts" label="User Accounts">
          <TreeItem
            itemId="viewUserAccounts"
            label={
              <Link
                to="/user-accounts"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                view{" "}
              </Link>
            }
          />
        </TreeItem>

        <TreeItem itemId="exit" label="Exit">
          <TreeItem
            itemId="signOut"
            label={
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                {" "}
                Sign Out{" "}
              </Link>
            }
          />
        </TreeItem>
      </SimpleTreeView>
    </Box>
  );
};

export default AdminMenu;
