import React from "react";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

// Define a styled icon with responsive sizes
const StyledResponsiveIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  // Default size for xs screens
  fontSize: "16px", // Equivalent to FontAwesomeIcon size="lg"
  // Adjust sizes for other breakpoints
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px", // Larger on sm screens
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px", // Larger on md screens
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px", // Larger on lg screens
  },
}));

const ResponsiveIcon = (props) => {
  // Spread props to the StyledResponsiveIcon for flexibility
  return <StyledResponsiveIcon {...props} />;
};

// Prop validation (optional but recommended for better developer experience)
ResponsiveIcon.propTypes = {
  icon: PropTypes.object.isRequired, // Ensure icon prop is passed and is an object
};

export default ResponsiveIcon;
