import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  Hidden,
  Paper,
} from "@mui/material";
import axios from "axios";
import { useUser } from "../UserContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function Trades() {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [commodities, setCommodities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedCommodity, setSelectedCommodity] = useState(null);
  const location = useLocation();
  const { user } = useUser();

  const fetchCommodities = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://theyard.accentrixcloud.com/api/commodities"
      );
      setCommodities(response.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch commodities:", error);
      setLoading(false);
      setCommodities([]);
    }
  };

  // Use useEffect to call fetchCommodities on component mount
  useEffect(() => {
    fetchCommodities();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    if (!value) {
      setLoading(true);
      fetchCommodities();
    }
  };

  const filteredCommodities = commodities.filter(
    (commodity) =>
      commodity.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      commodity.qty?.toString().includes(searchText) ||
      commodity.price?.toString().includes(searchText) ||
      commodity.uom?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleBuy = (commodity) => {
    setSelectedCommodity(commodity);

    setOpen(true); // Open the modal to accept phone number
  };

  const triggerSTKPush = async () => {
    try {
      const response = await axios.post(
        "https://theyard.accentrixcloud.com/stk_push",
        {
          phone_number: user.phoneNumber,
          amount: amount,
        }
      );
      console.log("STK Push initiated:", response.data);
      setOpen(false); // Close the modal after successful operation
    } catch (error) {
      console.error("Error initiating STK Push:", error);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" component="div">
          Live Trades
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <Hidden smDown>
                <TableCell>Qty</TableCell>
              </Hidden>
              <TableCell>Price (Ksh)</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommodities.length > 0 ? (
              filteredCommodities.map((commodity) => (
                <TableRow key={commodity.id}>
                  <TableCell>{commodity.name}</TableCell>
                  <Hidden smDown>
                    <TableCell>{`${commodity.qty} ${commodity.uom}`}</TableCell>
                  </Hidden>
                  <TableCell>
                    {`${commodity.price}`}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleBuy(commodity)}
                      sx={{
                        bgcolor: "#df326f",
                        color: "white",
                        "&:hover": { bgcolor: "#c3005d" },
                      }}
                      size="small"
                    >
                      Buy
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", color: "red" }}
                >
                  {loading ? "Loading commodities..." : "No records to display"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="div">
            Complete Purchase
          </Typography>
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            value={user.phoneNumber}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Amount"
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            onClick={triggerSTKPush}
            sx={{
              mt: 2,
              bgcolor: "#df326f",
              color: "white",
              "&:hover": { bgcolor: "#c3005d" },
            }}
            fullWidth
          >
            Pay with M-Pesa
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
