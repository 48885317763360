import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext'
const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user || !user.userId) {
    // Redirect them to the home page, but save the current location they were trying to go to
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children; // If user exists, return the children components (protected components)
};

export default ProtectedRoute;
