import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@mui/material";
import axios from "axios";

function Projection({ id }) { // Accept `id` directly as a prop
  const [projectionData, setProjectionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProjectionData() {
      setLoading(true);
      try {
        const { data } = await axios.get(`https://theyard.accentrixcloud.com/api/commodities/${id}`);
        // Assume the data is structured correctly and contains the necessary fields
        const projections = generateProjections(data);
        setProjectionData(projections);
      } catch (error) {
        console.error("Failed to fetch projection data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchProjectionData();
  }, [id]); // `id` is now a prop

  const generateProjections = (data) => {
    let projections = [];
    let capital = data.commodity.qty * data.commodity.price * 0.1; // Adjusted to use data directly

    for (let week = 1; week <= 12; week++) {
      let additionalCapital = week % 4 === 0 ? 5000 : 0; // Example: add extra capital every 4 weeks
      let effectiveCapital = capital + additionalCapital;
      let tonsPurchased = Math.floor(effectiveCapital / data.commodity.price);
      let grossProfit = tonsPurchased * data.commodity.price * 1.1; // Example: 10% profit

      projections.push({
        week,
        name: data.commodity.name, 
        startingCapital: capital.toLocaleString(),
        additionalCapital: additionalCapital.toLocaleString(),
        effectiveCapital: effectiveCapital.toLocaleString(),
        tonsPurchased: tonsPurchased.toLocaleString(),
        grossProfit: grossProfit.toLocaleString(),
        cumulativeReinvested: (capital + grossProfit).toLocaleString() // Update for clarity
      });

      capital = grossProfit; // Next week's capital is this week's gross profit
    }

    return projections;
  }

  return (
    <div >
      <Typography variant="h6">
        {loading ? "Loading..." : `ROI for ${projectionData[0].name || 'Commodity'}`}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Week</TableCell>
                <TableCell>Capital (Ksh)</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Returns (Ksh)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectionData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.week}</TableCell>
                  <TableCell>{item.startingCapital}</TableCell>
                  <TableCell>{item.tonsPurchased}</TableCell>
                  <TableCell>{item.grossProfit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          <Button onClick={() => window.history.back()} style={{ marginTop: 20 }}>
            Back
          </Button>
        </>
      )}
    </div>
  );
}

export default Projection;
