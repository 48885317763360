import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Container,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

function SignupForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    organization: "",
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [activationLink, setActivationLink] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
 
};

const handleSubmit = async (event) => {
event.preventDefault();
setLoading(true); // Start loading before the request
try {
const response = await axios.post(
"https://theyard.accentrixcloud.com/api/users",
formData
);
setActivationLink(response.data.activationLink);
setModalMessage(
"Account created successfully. Please check your email for activation link."
);
setIsError(false);
setShowModal(true);
} catch (error) {
setModalMessage(
`Failed to create account: ${ error.response ? error.response.data.message : error.message }`
);
setIsError(true);
setShowModal(true);
} finally {
setLoading(false); // Stop loading after the response
}
};

const handleCloseModal = () => {
setShowModal(false);
};

return (
<Container maxWidth="sm">
<Typography variant="h5" component="h1" gutterBottom color="#3f51b5" alignSelf='center'>
Create Account
</Typography>
<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
<TextField
       margin="normal"
       required
       fullWidth
       id="name"
       label="Name"
       name="name"
       autoComplete="name"
       autoFocus
       value={formData.name}
       onChange={handleChange}
     />
<TextField
       margin="normal"
       required
       fullWidth
       id="email"
       label="Email Address"
       name="email"
       autoComplete="email"
       value={formData.email}
       onChange={handleChange}
     />
<TextField
       margin="normal"
       required
       fullWidth
       id="phoneNumber"
       label="Phone Number"
       name="phoneNumber"
       autoComplete="tel"
       value={formData.phoneNumber}
       onChange={handleChange}
       helperText="Enter a phone number starting with 254"
     />
<Button
type="submit"
fullWidth
variant="contained"
sx={{ mt: 3, mb: 2, bgcolor: "#4caf50" }}
disabled={loading}
>
{loading ? <CircularProgress size={24} color="inherit" /> : "Register"}
</Button>
</Box>

  {/* Modal for showing the server response */}
  <Dialog open={showModal} onClose={handleCloseModal}>
    <DialogTitle>{isError ? "Error" : "Success"}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {modalMessage}
        {activationLink && (
          <>
            <br />
            Activation Link: <a href={activationLink}>{activationLink}</a>
          </>
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseModal}>OK</Button>
    </DialogActions>
  </Dialog>
</Container>
);
}

export default SignupForm;