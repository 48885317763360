import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { red } from "@mui/material/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Users() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState({
    id: "",
    name: "",
    qty: "",
    price: "",
    uom: "",
  });
  const [searchText, setSearchText] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [currentDeletingId, setCurrentDeletingId] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        "https://theyard.accentrixcloud.com/api/accounts"
      );
      setUsers(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (user) => {
    setEditUser({ ...user });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditUser({ id: "", name: "", email: "", phone_number: "", created_at: "" });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://theyard.accentrixcloud.com/api/accounts/${currentDeletingId}`
      );
      setUsers(users.filter((user) => user.id !== currentDeletingId));
      setFeedbackMessage("User successfully deleted.");
      setDeleteSuccessOpen(true);
      setError(false);
    } catch (error) {
      setFeedbackMessage("Failed to delete user: " + error.message);
      setError(true);
    } finally {
      handleCloseDeleteConfirmation();
    }
  };

  const handleOpenDeleteConfirmation = (id) => {
    setCurrentDeletingId(id);
    setConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setConfirmationOpen(false);
    setCurrentDeletingId(null);
  };

  const handleCloseFeedbackModal = () => {
    setDeleteSuccessOpen(false);
    setError(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const method = editUser.id ? "put" : "post";
    const url = `https://theyard.accentrixcloud.com/api/accounts${
      editUser.id ? `/${editUser.id}` : ""
    }`;

    try {
      const { data } = await axios[method](url, editUser);
      if (editUser.id) {
        // If editing, update the users list with the new data from the server
        setUsers((prevUsers) =>
          prevUsers.map((com) => (com.id === editUser.id ? data : com))
        );
      } else {
        // If adding new, just append it to the list
        setUsers((prevUsers) => [...prevUsers, data]);
      }
      handleClose();
    } catch (error) {
      console.error("Error saving user:", error);
    } finally {
      setLoading(false);
      fetchUsers(); // Re-fetch all users after an update to ensure sync
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filteredUsers =
    searchText.length > 0
      ? users.filter(
          (user) =>
            user.name.toLowerCase().includes(searchText) ||
            user.phone_number.toLowerCase().includes(searchText)
        )
      : users;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">Users</Typography>
        <TextField
          size="small"
          label="Search Users"
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
          sx={{ marginRight: 2 }}
        />
        <Button
          onClick={() =>
            openEditModal({ id: "", name: "", email: "", phone_number: "", created_at: "" })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Created </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone_number}</TableCell>
                  <TableCell>{user.created_at}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => openEditModal(user)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenDeleteConfirmation(user.id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: "center" }}>
                  {users.length === 0
                    ? "No records to display"
                    : "No matching records found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-user-modal-title"
      >
        <Box sx={style}>
          <DialogTitle id="edit-user-modal-title">
            {editUser?.id ? "Edit User" : "Add New User"}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              name="name"
              value={editUser?.name || ""}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={editUser?.email || ""}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={editUser?.phone_number || ""}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained">
              {editUser?.id ? "Save Changes" : "Add User"}
            </Button>
          </DialogActions>
        </Box>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Dialog open={confirmationOpen} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this user?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation}>No</Button>
          <Button
            onClick={handleDelete}
            autoFocus
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Modal */}
      <Dialog open={deleteSuccessOpen} onClose={handleCloseFeedbackModal}>
        <DialogTitle>{error ? "Error" : "Success"}</DialogTitle>
        <DialogContent>
          <Typography>{feedbackMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeedbackModal}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Users;
