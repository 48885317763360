import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

import SignIn from "./SignIn";

import SignupForm from "../components/SignupForm";

import logo from "../assets/images/logos/logo.png";
import ads from "../assets/logo.png";
import bg2 from "../assets/bg2.jpg"
const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
  // Add more testimonials as needed
];

const cardData = [
  {
    title: "Real-Time Market Data",
    content:
      "Access up-to-date market data and trends to make informed trading decisions.",
    image: "https://source.unsplash.com/random?stock-market",
    action: "View Market Trends",
    actionLink: "/market",
  },
  {
    title: "Commodity Trading",
    content:
      "Trade a variety of commodities from grains to metals anywhere, anytime.",
    image: "https://source.unsplash.com/random?commodity",
    action: "Start Trading",
    actionLink: "/trade",
  },
  {
    title: "Portfolio Management",
    content:
      "Manage and review your investment portfolio to keep track of your assets and their performance.",
    image: "https://source.unsplash.com/random?portfolio",
    action: "Manage Portfolio",
    actionLink: "/portfolio",
  },
  {
    title: "Automated Trading",
    content:
      "Set up trading bots to automate your trading strategies and capitalize on market opportunities 24/7.",
    image: "https://source.unsplash.com/random?automation",
    action: "Automate Trades",
    actionLink: "/automation",
  },
  {
    title: "Secure Transactions",
    content:
      "All transactions are secured with industry-standard encryption, ensuring your data and assets are protected.",
    image: "https://source.unsplash.com/random?secure-transaction",
    action: "Learn More",
    actionLink: "/security",
  },
  {
    title: "Global Access",
    content:
      "Engage with international markets and expand your trading boundaries. Perfect for the diaspora looking to invest back home.",
    image: "https://source.unsplash.com/random?global-trade",
    action: "Go Global",
    actionLink: "/global",
  },
  // Add more card data as needed
];

const PrivacyPolicy = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    const endpoint = email.endsWith("@accentrixcloud.com")
      ? "https://theyard.accentrixcloud.com/api/accounts"
      : "https://theyard.accentrixcloud.com/api/users";
    const profileRoute = email.endsWith("@accentrixcloud.com")
      ? "/admin-profile"
      : "/user-profile";

    try {
      const response = await axios.post(
        `https://theyard.accentrixcloud.com${endpoint}`,
        { email, password }
      );
      if (response.status === 200) {
        navigate(profileRoute, { state: { accountDetails: response.data } });
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      setDialogMessage(`An error occurred while signing in: ${error.message}`);
      setOpen(true);
    }
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${bg2})`,
    position: "relative", // Needed for absolute positioning of the overlay
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  const logoStyle = {
    position: "absolute", // Absolute positioning within the relative banner
    top: 20, // Adjust these values based on your specific needs
    left: 20,
    height: "180px",
    zIndex: 10, // Make sure it's above the overlay
  };

  return (
    <div>
      <div style={bannerStyle}>
        <img src={logo} alt="Logo" style={logoStyle} />
        <div style={overlayStyle}></div>
      </div>
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
          minHeight: `calc(100vh - ${navbarHeight}px)`,
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={3} sx={{ margin: 'auto', padding: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mt: 2 }}>
            Privacy Policy
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            At The Yard - Trading House, your privacy is paramount. We are committed to maintaining the confidentiality, integrity, and security of personal information entrusted to us. This privacy policy explains how we handle and protect your personal data in relation to our commodities trading services, mobile money transfers, and communications.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Data Collection
          </Typography>
          <Typography paragraph>
            We collect personal data necessary for opening and maintaining your trading account. This includes your name, email address, and phone number. Your phone number is particularly crucial as it is used for mobile money transactions and to ensure secure log-in processes.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Use of Data
          </Typography>
          <Typography paragraph>
            Your personal data is used to manage your account, process transactions, provide customer support, and send notifications about your transactions and market updates. We also use your information to improve our platform and prevent fraudulent activities.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Data Sharing and Disclosure
          </Typography>
          <Typography paragraph>
            We do not sell or rent your personal data. We may share your information with mobile money services and financial institutions to facilitate your transactions. We also comply with legal requests from regulatory bodies and law enforcement as required by law.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Data Security
          </Typography>
          <Typography paragraph>
            We employ robust security measures to protect your data from unauthorized access, alteration, and destruction. Our security protocols include encryption, firewalls, and secure server facilities.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Your Rights
          </Typography>
          <Typography paragraph>
            You have the right to access, correct, or delete your personal data stored with us. You can also opt out of receiving marketing communications or notifications by following the unsubscribe options provided in every email.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Changes to Our Privacy Policy
          </Typography>
          <Typography paragraph>
            We reserve the right to modify this policy at any time in accordance with changing legal, regulatory, or operational requirements. We will notify you of any changes through our website or through direct communication.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Contact Information
          </Typography>
          <Typography paragraph>
            For any questions or concerns regarding our privacy practices, or to exercise your rights, please contact us at:
            <br />
            Email: info@accentrixcloud.com
            <br />
            Address: P.O Box 444 - 40405, Sare
          </Typography>
        </Paper>
      </Grid>
    </Grid>
    <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4} style={{ color: "#3f51b5", textAlign:'center' }}>
          <img src={ads} alt="Logo" style={{height: 80}} />
            <Typography >
            A product of Accentrix Data Systems Ltd
            </Typography>
            <Typography variant="caption" >All Rights Reserved  © {new Date().getFullYear()} </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} style={{flexGrow: 0.1, display: "flex", justifyContent: "center", flexDirection: "column", justifyContent: "center", alignItems: "center",}} >
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default PrivacyPolicy;
