import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { red } from "@mui/material/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Items() {
  const [commodities, setCommodities] = useState([]);
  const [open, setOpen] = useState(false);
  const [editCommodity, setEditCommodity] = useState({
    id: "",
    name: "",
    qty: "",
    price: "",
    uom: "",
  });
  const [searchText, setSearchText] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [currentDeletingId, setCurrentDeletingId] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchCommodities();
  }, []);

  const fetchCommodities = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        "https://theyard.accentrixcloud.com/api/commodities"
      );
      setCommodities(data);
    } catch (error) {
      console.error("Failed to fetch commodities:", error);
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (commodity) => {
    setEditCommodity({ ...commodity });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditCommodity({ id: "", name: "", qty: "", price: "", uom: "" });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditCommodity((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://theyard.accentrixcloud.com/api/commodities/${currentDeletingId}`
      );
      setCommodities(
        commodities.filter((commodity) => commodity.id !== currentDeletingId)
      );
      setFeedbackMessage("Commodity successfully deleted.");
      setDeleteSuccessOpen(true);
      setError(false);
    } catch (error) {
      setFeedbackMessage("Failed to delete commodity: " + error.message);
      setError(true);
    } finally {
      handleCloseDeleteConfirmation();
    }
  };

  const handleOpenDeleteConfirmation = (id) => {
    setCurrentDeletingId(id);
    setConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setConfirmationOpen(false);
    setCurrentDeletingId(null);
  };

  const handleCloseFeedbackModal = () => {
    setDeleteSuccessOpen(false);
    setError(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const method = editCommodity.id ? "put" : "post";
    const url = `https://theyard.accentrixcloud.com/api/commodities${
      editCommodity.id ? `/${editCommodity.id}` : ""
    }`;

    try {
      const { data } = await axios[method](url, editCommodity);
      if (editCommodity.id) {
        // If editing, update the commodities list with the new data from the server
        setCommodities((prevCommodities) =>
          prevCommodities.map((com) =>
            com.id === editCommodity.id ? data : com
          )
        );
      } else {
        // If adding new, just append it to the list
        setCommodities((prevCommodities) => [...prevCommodities, data]);
      }
      handleClose();
    } catch (error) {
      console.error("Error saving commodity:", error);
    } finally {
      setLoading(false);
      fetchCommodities(); // Re-fetch all commodities after an update to ensure sync
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filteredCommodities =
    searchText.length > 0
      ? commodities.filter(
          (commodity) =>
            commodity.name.toLowerCase().includes(searchText) ||
            commodity.uom.toLowerCase().includes(searchText)
        )
      : commodities;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">Commodities</Typography>
        <TextField
          size="small"
          label="Search Commodities"
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
          sx={{ marginRight: 2 }}
        />
        <Button
          onClick={() =>
            openEditModal({ id: "", name: "", qty: "", price: "", uom: "" })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>UOM</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommodities.length > 0 ? (
              filteredCommodities.map((commodity) => (
                <TableRow key={commodity.id}>
                  <TableCell>{commodity.name}</TableCell>
                  <TableCell>{commodity.qty}</TableCell>
                  <TableCell>{`Ksh ${commodity.price}`}</TableCell>
                  <TableCell>{commodity.uom}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => openEditModal(commodity)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenDeleteConfirmation(commodity.id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: "center" }}>
                  {commodities.length === 0
                    ? "No records to display"
                    : "No matching records found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-commodity-modal-title"
      >
        <Box sx={style}>
          <DialogTitle id="edit-commodity-modal-title">
            {editCommodity?.id ? "Edit Commodity" : "Add New Commodity"}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              name="name"
              value={editCommodity?.name || ""}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Quantity"
              name="qty"
              type="number"
              value={editCommodity?.qty || ""}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Price"
              name="price"
              type="number"
              value={editCommodity?.price || ""}
              onChange={handleInputChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="uom-label">Unit of Measure</InputLabel>
              <Select
                labelId="uom-label"
                label="Unit of Measure"
                id="uom-select"
                name="uom"
                value={editCommodity?.uom || ""}
                onChange={handleInputChange}
              >
                <MenuItem value="kg">Kilogram (kg)</MenuItem>
                <MenuItem value="grm">Gram (grm)</MenuItem>
                <MenuItem value="ton">Ton</MenuItem>
                <MenuItem value="unit">Unit</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained">
              {editCommodity?.id ? "Save Changes" : "Add Commodity"}
            </Button>
          </DialogActions>
        </Box>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Dialog open={confirmationOpen} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this commodity?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation}>No</Button>
          <Button
            onClick={handleDelete}
            autoFocus
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Modal */}
      <Dialog open={deleteSuccessOpen} onClose={handleCloseFeedbackModal}>
        <DialogTitle>{error ? "Error" : "Success"}</DialogTitle>
        <DialogContent>
          <Typography>{feedbackMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeedbackModal}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Items;
