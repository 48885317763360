import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from '../UserContext';

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");

  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useUser();

  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage("Please enter both email and password");
      setOpenError(true);
      return;
    }
    setLoading(true);
    const endpoint = "https://theyard.accentrixcloud.com/api/auth";
    const adminLogin = email.endsWith("@accentrixcloud.com");
    try {
      const response = await axios.post(endpoint, { email, password, admin: adminLogin });
      setLoading(false);
      if (response.data) {
        login({
          userId: response.data.id,
          username: response.data.name,
          email: response.data.email,
          phoneNumber: response.data.phone_number
        });
        const profileRoute = adminLogin ? "/admin-profile" : "/trading";
        navigate(profileRoute);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("Login failed: " + (error.response ? error.response.data.error : error.message));
      setOpenError(true);
    }
  };

  const handleOpenReset = () => setOpenReset(true);
  const handleCloseReset = () => setOpenReset(false);
  const handleCloseError = () => setOpenError(false);

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      setOpenError(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put("/api/users", { email, password: newPassword });
      setLoading(false);
      console.log("Password reset successfully:", response.data);
      handleCloseReset();
    } catch (error) {
      setLoading(false);
      setErrorMessage("Failed to reset password: " + (error.response ? error.response.data.message : error.message));
      setOpenError(true);
    }
  };

  const sendResetEmail = async () => {
    try {
      await axios.post("https://theyard.accentrixcloud.com/api/reset-password", { email: resetEmail });
      handleCloseReset();
      // You might want to show a success message or dialog here
      alert("If an account with that email exists, we've sent a password reset link to it.");
    } catch (error) {
      setErrorMessage("Failed to send reset email: " + (error.response ? error.response.data.error : error.message));
      setOpenError(true);
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '300px', margin: 'auto' }}>
      <Typography variant="h5" textAlign="center" marginBottom={2}>Sign In</Typography>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="dense"
      />
      <Box mt={2} textAlign="center">
        <Button variant="contained" color="primary" fullWidth onClick={handleLogin} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Sign In"}
        </Button>
      </Box>
      <Box mt={2} textAlign="center">
        <Button onClick={handleOpenReset}>Forgot password?</Button>
      </Box>

      <Dialog open={openError} onClose={handleCloseError}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReset} onClose={handleCloseReset}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={sendResetEmail}>Send Reset Email</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openError} onClose={handleCloseError}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>OK</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default SignIn;
