import React from "react";
import { Link, useNavigate  } from "react-router-dom";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import logo from "../assets/images/logos/logo.png";
import { useUser } from "../UserContext";

const UserMenu = () => {
  const logoStyle = {
    position: "absolute", // Absolute positioning within the relative banner

    height: "180px",
    zIndex: 10, // Make sure it's above the overlay
  };

  const { logout } = useUser();
  const navigate = useNavigate();

  const handleSignOut = () => {
    logout(); // Call the logout function from UserContext
    navigate("/"); // Redirect to homepage or login page
  };
  return (
    <Box sx={{ height: 220, flexGrow: 1, maxWidth: 400 }}>
      <SimpleTreeView>
        <TreeItem
          itemId="dashboard"
          label={
            <Link
              to="/trading"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              Dashboard{" "}
            </Link>
          }
        />
                
        <TreeItem itemId="myProjections" label="My ROI">
          <TreeItem
            itemId="viewProjections"
            label={
              <Link
                to="/projections"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                view{" "}
              </Link>
            }
          />
        </TreeItem>
        <TreeItem itemId="myFunds" label="My Profile">
          <TreeItem
            itemId="viewProfile"
            label={
              <Link
                to="/profile-settings"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                Settings{" "}
              </Link>
            }
          />
        </TreeItem>
        <TreeItem itemId="exit" label="Exit">
          <TreeItem
            itemId="signOut"
            label="Sign Out"
            onClick={handleSignOut}
          />
        </TreeItem>
      </SimpleTreeView>
      <img src={logo} alt="Logo" style={logoStyle} />
    </Box>
  );
};

export default UserMenu;
