import React, { useState, useEffect } from "react";
import { useUser } from '../UserContext'; // Adjust the path as necessary
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Settings() {
  const { user, setUser } = useUser();
  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState({ id: '', name: '', phone_number: '', email: '' });
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const method = editUser.userId ? "put" : "post";
    const url = `https://theyard.accentrixcloud.com/api/users/${editUser.userId}`;

    try {
      const { data } = await axios[method](url, editUser);
      // Update local user state
      if (editUser.userId === user.userId) {
        setUser(data);
      }
      setOpen(false);
    } catch (error) {
      console.error("Error saving user:", error);
    } finally {
      setLoading(false);
    }
  };

  // Modal open function for editing
  const openEditModal = (selectedUser) => {
    setEditUser(selectedUser);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" sx={{ mb: 2 }}>User Settings</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={user.userId}>
            <TableCell>{user.username}</TableCell>
            <TableCell>{user.phoneNumber}</TableCell>
            <TableCell>
              <IconButton onClick={() => openEditModal(user)} color="primary">
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="edit-user-modal-title"
      >
        <Box sx={{ ...style, width: 300 }}>
          <DialogTitle id="edit-user-modal-title">{editUser.userId ? "Edit User" : "Add New User"}</DialogTitle>
          <DialogContent>
            <TextField margin="normal" fullWidth label="Name" name="name" value={editUser.username} onChange={(e => setEditUser({ ...editUser, name: e.target.value }))} />
            <TextField margin="normal" fullWidth label="Phone Number" name="phone_number" value={editUser.phoneNumber} onChange={(e => setEditUser({ ...editUser, phone_number: e.target.value }))} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleSave} variant="contained">Save</Button>
          </DialogActions>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default Settings;
